<template>
    <div class="background py-16">
        <VerifyEmail />
    </div>
</template>

<script>
import VerifyEmail from "@/components/elements/auth/verify-email/VerifyEmail";

export default {
    name: 'DocumentManagementVerifyEmail',

    components: {
      VerifyEmail,
    },

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
//
</style>