<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
        <v-card v-if="verifyEmailSuccessfully" class="transparent" align="center" flat>
            <v-card class="pa-5 mr-3 mb-3 mt-11" width="600" align="center" flat>
                <v-img
                    alt="Vuetify Logo"
                    src="@/assets/img/payment/successful.png"
                    width="150"
                />
                <h1 class="mt-10">Email Verification Successful!</h1>

                <p>Email Verification Successful!</p>

                <h5>We Love You!</h5>

                <!-- Home -->
                <v-btn
                    v-if="auth.loggedIn"
                    color="btn btn-primary white--text mt-4"
                    :to="{ name: 'DashboardInbox' }"
                >
                    Home
                </v-btn>

                <!-- Login -->
                <v-btn
                    v-else
                    color="btn btn-primary white--text mt-4"
                    :to="{ name: 'Login' }"
                >
                    Login
                </v-btn>
            </v-card>
        </v-card>

        <v-card v-else class="transparent" align="center" flat>
            <v-card class="pa-5 mr-3 mb-3 mt-11" width="600" align="center" flat>
              <v-img
                alt="Vuetify Logo"
                src="@/assets/img/payment/error.svg"
                width="150"
              />
    
              <h1 class="mt-10">An Error Occurred With Verifying Email</h1>
    
              <p>Thank you for using our app.</p>
    
              <h5>We Love You!</h5>
    
              <!-- Login -->
              <v-btn
                color="btn btn-primary white--text mt-4"
                :to="{ name: 'Login' }"
              >
                Login
              </v-btn>
            </v-card>
          </v-card>

        <!-- Overlay -->
        <v-overlay :value="overlay" z-index="1000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-card>
  </div>
</template>
  
<script>
import { mapMutations , mapState} from "vuex";

export default {
  name: 'DocumentManagementVerifyEmail',

   data: () => ({
    overlay: false,

    verifyEmailSuccessfully: true,

    token: "",
   }),

  computed: {    
    ...mapState({ auth: "auth" }),
  },

  created() {
     let parameters = this.$route.query;

    if ("token" in parameters) {
       this.token = parameters.token;

       this.verifyEmail();
    } else{
        this.verifyEmailSuccessfully = false;
    }
  },

  mounted() {},

  methods: {
    // mapMutations
    ...mapMutations({
      setUser: "auth/setUser",
    }),
    
    // verifyEmail
    async verifyEmail() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `verify-email`,
        {
          token: this.token,          
        },
        "Email Verified Successfully!"
      );

      if (res.status == 200) {
        // loggedIn
        if (this.auth.loggedIn) {
            // getUserDetails
            await this.getUserDetails();
        }
      } else {
        // verifyEmailSuccessfully
        this.verifyEmailSuccessfully = false;
      } 

      this.overlay = false;
    },

    // getUserDetails
    async getUserDetails() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("user-details");

      if (res.status == 200) {
        const data = res.data;

        this.setUser(data);
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
